import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Mijin from "mijin";
import VueTyperPlugin from 'vue-typer'

import "./css/main.scss";
import 'vue-code-highlight/themes/window.css'
import './css/prism-tomorrow.css'
//import './css/prism-vs.css'


Vue.use(Mijin);
Vue.use(VueTyperPlugin);

Vue.config.productionTip = false;

Vue.prototype.$w = window;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
