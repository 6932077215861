<template>
  <div id="app">
    <div class="e-nav" align="center">
      <div align="left" style="max-width: 600pt; position: relative">
        <MjLink
        v-if="showBlog"
          href="https://blog.escher.wtf"
          :color="`white`"
          class="e-grow"
          external
          style="right: 0; position: absolute"
          >blog</MjLink
        >
        <img
          @click="$router.push('/')"
          class="e-logo e-grow"
          src="@/assets/escher.png"
        />
      </div>
    </div>
    <router-view />
    <div class="e-footer" align="center">
      <div class="e-content" align="left">
        © Escher Wright-Dykhouse 2021
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "home",
  computed: {
      showBlog() {
        return location.href.includes("escher.wtf") || location.href.includes("localhost")
      }
  },
  mounted() {
    // The debounce function receives our function as a parameter
    const debounce = (fn) => {
      // This holds the requestAnimationFrame reference, so we can cancel it if we wish
      let frame;

      // The debounce function returns a new function that can receive a variable number of arguments
      return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
          cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
          // Call our function and pass any params we received
          fn(...params);
        });
      };
    };

    // Reads out the scroll position and stores it in the data attribute
    // so we can use it in our stylesheets
    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.scrollY.toString();
    };

    // Listen for new scroll events, here we debounce our `storeScroll` function
    document.addEventListener("scroll", debounce(storeScroll), {
      passive: true,
    });

    // Update scroll position for first time
    storeScroll();
  },
};
</script>

<style lang="scss"></style>
