<template>
  <div class="home" align="center">
    <div class="e-home">
      <div class="e-text-wrapper" align="left">
        <vue-typer
          align="left"
          class="e-text"
          :text="[
            `Hi! 👋`,
            `My name is Escher`,
            `I make websites and mobile apps`,
            `among other things 👀`,
          ]"
        ></vue-typer>
      </div>
      <div class="e-bottom-wrapper">
        <span class="e-down-arrow"
          ><MjIcon name="chevron-down" size="lg" />projects</span
        >
      </div>
    </div>

    <!-- <div class="e-content" style="margin: 16pt 16pt" align="left"> -->
    <div
      v-for="(proj, i) in projects"
      :key="proj.title"
      style="padding-top: 32pt"
      :class="[
        `from-${proj.color}-100`,
        `to-${proj.color}-50;`,
        i % 2 == 1 ? 'e-proj-dark' : null,
      ]"
    >
      <div
        align="left"
        class="e-content"
        style="margin-top: 0; margin-bottom: 0"
      >
        <span class="e-text-header" :class="[`text-${proj.color}-500`]">{{
          proj.header
        }}</span>
        <h2>
          {{ proj.title
          }}<small style="margin-left: 7pt">{{ proj.year }}</small>
        </h2>
        <div
          v-if="i % 2 == 0"
          class="grid grid-cols-3 gap-8 e-proj-body"
          align="center"
        >
          <div class="col-span-3 sm:col-span-1 e-proj-img-wrapper">
            <img
              :class="{ 'e-proj-img-mockup': proj.mockup }"
              class="e-proj-img"
              :src="proj.image"
            />
            <MjButton
              :class="[
                `bg-${proj.color}-500`,
                `hover:bg-${proj.color}-600`,
                `border-${proj.color}-500`,
                `hover:border-${proj.color}-600`,
              ]"
              icon-right
              @click="$w.open(proj.link)"
            >
              <template #icon>
                <MjIcon name="external-link" />
              </template>
              Check it out
            </MjButton>
            <div
              class="overlay bg-gradient-to-tl via-transparent"
              :class="[`from-${proj.color}-700`]"
            />
          </div>
          <div align="left" class="col-span-3 sm:col-span-2 e-proj-text">
            {{ proj.body }}<br />
            <div style="height: 46pt" />
            <div class="e-proj-icons" style="right: 0">
              <small class="e-proj-icon-text">Built With</small>
              <div v-for="icon in proj.icons" :key="icon" class="e-proj-icon">
                <img :src="icon" />
              </div>
            </div>
          </div>
        </div>
        <div v-else align="center" class="grid grid-cols-3 gap-8 e-proj-body">
          <div align="left" class="col-span-3 sm:col-span-2 e-proj-text">
            {{ proj.body }}<br />
            <div style="height: 46pt" />
            <div class="e-proj-icons">
              <small class="e-proj-icon-text">Built With</small>
              <div v-for="icon in proj.icons" :key="icon" class="e-proj-icon">
                <img :src="icon" />
              </div>
            </div>
          </div>
          <div class="col-span-3 sm:col-span-1 e-proj-img-wrapper">
            <img
              :class="{ 'e-proj-img-mockup': proj.mockup }"
              class="e-proj-img"
              :src="proj.image"
            />
            <MjButton
              class="btn-left"
              :class="[
                `bg-${proj.color}-500`,
                `hover:bg-${proj.color}-600`,
                `border-${proj.color}-500`,
                `hover:border-${proj.color}-600`,
              ]"
              icon-right
              @click="$w.open(proj.link)"
            >
              <template #icon>
                <MjIcon name="external-link" />
              </template>
              Check it out
            </MjButton>
            <div
              class="overlay bg-gradient-to-tr via-transparent"
              :class="[`from-${proj.color}-700`]"
            />
          </div>
        </div>
      </div>
      <MjDivider style="margin-top: 32pt" />
    </div>
    <div class="e-content compensate" align="center">
      <div class="grid grid-cols-1 sm:grid-cols-2" align="left">
        <div
          v-for="proj in small_projects"
          :key="proj.title"
          class="col-span-1 e-proj-small-proj"
          :class="[proj.isDivider ? 'sm:col-span-2' : null]"
        >
          <div v-if="proj.isDivider">
            <MjDivider />
          </div>
          <div v-else>
            <span class="e-text-header" :class="[`text-gray-400`]">{{
              proj.header
            }}</span>
            <h2>
              {{ proj.title
              }}<small style="margin-left: 7pt">{{ proj.year }}</small>
            </h2>
            <div class="e-proj-icons-small">
              <div v-for="icon in proj.icons" :key="icon" class="e-proj-icon">
                <img :src="icon" />
              </div>
            </div>
            <p class="e-proj-text" style="margin-top: 20pt">
              {{ proj.body }}
            </p>
            <MjButton
              variant="secondary"
              icon-right
              style="margin-top: 16pt"
              @click="$w.open(proj.link)"
            >
              <template #icon>
                <MjIcon name="external-link" />
              </template>
              Check it out
            </MjButton>
          </div>
        </div>
      </div>
    </div>
    <div align="center" class="mid-section-text">
      <div align="left" class="text">About Me</div>
    </div>
    <div align="left" class="e-content">
      <div style="margin-top: -60pt" align="center">
        <vue-code-highlight language="javascript">
          <pre>
class Escher {
  constructor() {
    this.name = '🙋‍♂️ Escher Wright-Dykhouse',
    this.location = '📍 Portland, OR'
    this.age = new Date().getFullYear() - 2003
  }

  skills = [
    'Swift', /* 4 Years */ 'Node.js', /* 3 Years */ 
    'TypeScript', /* 2 Years */ 'HTML/CSS', /* 2 Years */ 
    'Flutter/Dart', /* 2 Years */ 'Firebase', /* 2 Years */ 
    'Vue', /* 1 Year */ 'Svelt', /* 1 Year */
  ]
}
      </pre>
        </vue-code-highlight>
      </div>
      <h3 style="margin-top: 64pt; margin-bottom: 16pt">Employ Me</h3>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div
          @click="$w.open(contact.link)"
          v-for="contact in professional_contacts"
          :key="contact.title"
          class="e-contact-square"
          :class="[`hover:bg-${contact.color}-500`]"
        >
          <MjIcon class="icon" size="lg" :name="contact.icon" />{{
            contact.title
          }}<br />
          <small>{{ contact.subtitle }}</small>
        </div>
      </div>
      <h3 style="margin-top: 32pt; margin-bottom: 16pt">Follow Me</h3>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-4">
        <div
          @click="$w.open(contact.link)"
          v-for="contact in casual_contacts"
          :key="contact.title"
          class="e-contact-square"
          :class="[`hover:bg-${contact.color}-500`]"
        >
          <MjIcon class="icon" size="lg" :name="contact.icon" />{{
            contact.title
          }}<br />
          <small>{{ contact.subtitle }}</small>
        </div>
      </div>

      <div style="height: 64pt" />
      
    </div>
    
    <!-- </div> -->
  </div>
</template>

<script>
import { component as VueCodeHighlight } from "vue-code-highlight";

export default {
  name: "Home",
  components: { VueCodeHighlight },
  data() {
    return {
      projects: [
        {
          header: "Virtual Gallery",
          title: "RobertCalvo.com",
          body:
            "This website showcases the work and legacy of local artist Robert E. Calvo (1949 - 2015). The project is made up of a frontend written in Vue using the Ant Design library and a backend/CMS powered by Firebase. Still under development.",
          link: "https://robertcalvo.com",
          image: require("@/assets/proj-1.png"),
          color: "indigo",
          year: "2021",
          icons: [
            require("@/assets/icon-vue.png"),
            require("@/assets/icon-html.png"),
            require("@/assets/icon-css.png"),
            require("@/assets/icon-firebase.png"),
          ],
        },
        {
          header: "Social Snacking",
          title: "SNAX",
          body: `Snax, the social snacking platform of the future. Snax is a cross-platform mobile app that serves to provide unbiased reviews on a huge number of snacks. Users can rate snacks using 8 carefully chosen criterias, each representing a different aspect of the snacking experience. This app was built in Flutter using the Dart programming language and leverages Firebase's Firestore and Cloud Functions to store and analyze data. Download the beta from @snaxappofficial on Instagram (iOS only at this time).`,
          link: "https://orifriesen.wixsite.com/mysite/snax",
          image: require("@/assets/proj-2.png"),
          mockup: true,
          color: "red",
          year: "2021",
          icons: [
            require("@/assets/icon-flutter.png"),
            require("@/assets/icon-firebase.png"),
            require("@/assets/icon-node.png"),
          ],
        },
        {
          header: "The Tinder of Recruiting",
          title: "Recrinder",
          body: `Recrinder is a healthcare recruitment and matchmaking tool for clients and customers to view real time information about job openings and locations. By using a login to access the user can view hospital information like bed size, OR suites, estimated salaries, and other job details. Once the user chooses a sort, by swiping left or right they can find their next healthcare placement opportunity.`,
          link: "https://apps.apple.com/us/app/recrinder/id957528844",
          image: require("@/assets/proj-3.png"),
          color: "green",
          mockup: true,
          year: "2019",
          icons: [require("@/assets/icon-flutter.png")],
        },
        {
          header: "A Better OriTalk",
          title: "escherTalks",
          body: `escherTalks is a social media platform built in NodeJS and Swift, leveraging a MySQL database. The app features chats, stories, stickers, music, friends and more. Download the beta at eschertalks.com!`,
          link: "https://eschertalks.com",
          image: require("@/assets/proj-4.png"),
          color: "blue",
          year: "2018",
          mockup: true,
          icons: [
            require("@/assets/icon-swift.png"),
            require("@/assets/icon-node.png"),
          ],
        },
      ],
      small_projects: [
        {
          header: "Superlative",
          title: "Weather Site",
          year: "2021",
          color: "gray",
          body: `A simple weather website to display current conditions and an hourly forecast.`,
          link: "https://weather.eschr.us/",
          icons: [
            require("@/assets/icon-vue.png"),
            require("@/assets/icon-html.png"),
            require("@/assets/icon-css.png"),
          ],
        },
        {
          header: "Physics Simulation",
          title: "Buoyancy Sim",
          year: "2019",
          body: `An AP Physics 2 project that demonstrates how objects of different densities float or sink using the buoyancy equation.`,
          link: "https://buoyancy.eschr.us/",
          icons: [
            require("@/assets/icon-jquery.png"),
            require("@/assets/icon-html.png"),
            require("@/assets/icon-css.png"),
          ],
        },
        {
          isDivider: true,
          title: "divider",
        },
        {
          header: "Mathematical",
          title: "Audio Encrypter",
          year: "2020",
          body: `An AP Calculus project that uses music to encrypt/decrypt text by taking frequency samples at certain points along an audio track. Requires a Chromium browser.`,
          link: "https://encrypt.eschr.us/",
          icons: [
            require("@/assets/icon-vue.png"),
            require("@/assets/icon-html.png"),
            require("@/assets/icon-css.png"),
          ],
        },
        {
          header: "FTC Wilson Robotics",
          title: "Team 7776 Site",
          year: "2019",
          body: `A website showcasing FTC Team 7776 "Loose Screws" 2019 robot, CAD, and notebook.`,
          link: "https://7776.eschr.us/",
          icons: [
            require("@/assets/icon-html.png"),
            require("@/assets/icon-css.png"),
          ],
        },
      ],
      professional_contacts: [
        {
          title: "Email",
          subtitle: "me@eschr.us",
          icon: "mail",
          color: "yellow",
          link: "mailto:me@eschr.us",
        },
        {
          title: "LinkedIn",
          subtitle: "@escherwd",
          icon: "linkedin",
          color: "blue",
          link: "https://www.linkedin.com/in/escherwd/",
        },
        {
          title: "GitHub",
          icon: "github",
          subtitle: "@escherwd",
          color: "purple",
          link: "https://github.com/escherwd",
        },
      ],
      casual_contacts: [
        {
          title: "Instagram",
          subtitle: "@escherwd",
          icon: "instagram",
          color: "pink",
          link: "https://instagram.com/escherwd",
        },
        {
          title: "Facebook",
          subtitle: "@escher.wd",
          icon: "facebook",
          color: "blue",
          link: "https://www.facebook.com/escher.wd",
        },
        
        {
          title: "SoundCloud",
          icon: "play",
          subtitle: "@escherwd",
          color: "orange",
          link: "https://soundcloud.com/escherwd",
        },
        {
          title: "Bandcamp",
          icon: "music",
          subtitle: "@escherwd",
          color: "blue",
          link: "https://bandcamp.com/escherwd",
        },
        {
          title: "GeoGuessr",
          icon: "map-pin",
          subtitle: "Escher",
          color: "red",
          link: "https://www.geoguessr.com/user/6032264a22be7e000170d8c4",
        },
      ],
    };
  },
};
</script>
